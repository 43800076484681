import { action, observable } from 'mobx';

import PlainModel from './PlainModel';

/**
 * A smaller inteface for "consumers" of PagingInfoModel, who don't need to create/serialize the model
 *
 * (feel free to add if anything useful is missed -- that doesn't reveal too much encapsulated stuff)
 */
export type PagingInfo = Pick<
  PagingInfoModel,
  'page' | 'page_size' | 'incrementPage' | 'resetPage'
>;

export class PagingInfoModel extends PlainModel {
  @observable public search?: string;
  @observable public page?: number;
  @observable public page_size?: number;

  @action
  incrementPage = (): void => {
    this.page += 1;
  };

  @action
  resetPage = (): void => {
    this.page = 1;
  };

  toJSON(): Record<string, any> {
    return ['search', 'page', 'page_size'].reduce((acc, field) => {
      if (!!this[field]) {
        acc[field] = this[field];
      }
      return acc;
    }, {});
  }
}

export default PagingInfoModel;
