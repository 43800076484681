/**
 * Used (at this time) for filtering requests to load Aligns from `ServerRouteHelper.api.teams.exercises.list`.
 * This enum matches BE `App\Enums\ExerciseStatus`
 */
export enum ExerciseStatus {
  Open = 'open',
  Closed = 'closed',
}

export enum ExerciseTeamsRequestParam {
  STATUS = 'status',
  PAGE_SIZE = 'page_size',
}
