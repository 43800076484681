import { assign } from 'lodash';
import type { IObservableArray } from 'mobx';
import { action, computed, observable } from 'mobx';
import moment from 'moment';

import type { AlignModel, MemberModel, OrganizationModel } from 'app/models';
import Model, { ModelJson } from 'app/models/Model';
import { ModelItem } from 'app/models/ModelItem';
import { ModelList } from 'app/models/ModelList';
import PulseTemplateModel from 'app/models/PulseTemplateModel';
import TeamGameModel from 'app/models/TeamGameModel';
import { TeamStore } from 'app/stores/TeamStore';

import allModels from './allModels';
import { ExerciseStatus } from './ExerciseFilterStatusEnum';
import ExerciseTypeModel from './ExerciseTypeModel';
import { ReadonlyModelList } from './ReadonlyModelList';

const OLD_ALIGN_MONTHS_THRESHOLD = 4;

export interface TeamProps {
  name?: string;
  organization_id?: number;
  manager?: any;
  secondary_manager?: any;
  group_ids?: number[];
  group_map?: { [division: number]: number };
}

export interface MinimalAlignResource {
  id: number;
  exercise_type_name: string;
  closed_at: string;
  created_at: string;
  is_closed: boolean;
}

export interface TeamGroupResource {
  id: number;
  name: string;
  team_group_division_id: number;
}

/**
 * TeamModel is used to represent Teams
 */
export class TeamModel extends Model {
  static _store: TeamStore;

  @observable id: number;
  @observable organization_id: number;
  @action setOrganizationID = (id: number): void => {
    this.organization_id = id;
  };

  @observable name: string;
  @observable group_ids?: number[];
  @observable code?: string;
  @observable created_at?: string;
  @observable invite_link?: string;
  @observable is_top_team?: boolean;
  @observable can_use_discussion_space?: boolean;
  @observable any_perspective_invites?: boolean;

  @observable currentUserCanManage?: boolean;
  @observable currentUserCanCoach?: boolean;
  @observable group_map: { [division: number]: number } = {};
  @observable manager_id?: number;
  @observable members_count?: number;
  @observable name_updated?: boolean;

  @observable membersWithPerspectiveCount?: number;
  @observable membersCount?: number;

  @observable pulses_count?: number;
  @observable pulses_draft_count?: number;
  @observable pulses_disabled_count?: number;
  @observable pulses_active_count?: number;
  @observable latestAlign?: MinimalAlignResource;
  @observable hasActivePulse?: boolean;
  @observable active_onboarding_link_count?: number;

  @observable team_groups?: TeamGroupResource[];

  @observable align = new ModelItem<AlignModel>(allModels.AlignModel);
  /**
   * - See computed `openAligns` and `closedAligns` for external readonly access to loaded aligns
   * - Reference `AlignStore.isLoadingTeamAligns` for loading state
   *   - (the fact that I'm writing "check a value on AlignStore" is an encapsulation smell -- why is AlignStore the
   *     controller of TeamModel._aligns? But at this time that's too big scope to consider. So just this comment
   *     saying where to find the loading state)
   */
  @observable private readonly _aligns = new Map<ExerciseStatus, ModelList<AlignModel>>([
    [ExerciseStatus.Open, new ModelList<AlignModel>(allModels.AlignModel)],
    [ExerciseStatus.Closed, new ModelList<AlignModel>(allModels.AlignModel)],
  ]);

  @observable all_aligns = new ModelList<AlignModel>(allModels.AlignModel);
  @observable members = new ModelList<MemberModel>(allModels.MemberModel);
  @observable newMembers = new ModelList<MemberModel>(allModels.MemberModel);
  @observable manager = new ModelItem<MemberModel>(allModels.MemberModel);
  @observable secondary_manager = new ModelItem<MemberModel>(allModels.MemberModel);
  @action setSecondaryManager = (manager) => assign(this.secondary_manager, manager);
  @observable organization = new ModelItem<OrganizationModel>(allModels.OrganizationModel);
  @observable exercises = new ModelList<AlignModel>(allModels.AlignModel);
  @observable pulse_templates = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable pulse_templates_disabled = new ModelList<PulseTemplateModel>(PulseTemplateModel);
  @observable exercise_types?: ExerciseTypeModel[] = [];
  @observable team_games? = new ModelList<TeamGameModel>(TeamGameModel);
  @observable can_view_team?: boolean;
  @observable is_manager_coach_disabled?: boolean;

  @observable completed_align_templates?: IObservableArray<{
    name: string;
    most_recent_exercise: {
      link: string;
      created_at: string;
    };
  }>;
  @observable completed_align_templates_count?: number;

  asJSON() {
    const { name, group_ids, organization_id } = this;

    if (Object.values(this.group_map).length > 0) {
      this.associateGroupMap();
    }

    return {
      name,
      group_ids,
      organization_id,
    };
  }

  /**
   * Results are sorted newest first by created_at, and are paged. Call `AlignStore.getTeamAligns()` to load / load more
   * - Reference `AlignStore.isLoadingTeamAligns` for loading state
   */
  @computed
  get openAligns(): ReadonlyModelList<AlignModel> {
    return this._aligns.get(ExerciseStatus.Open);
  }

  /**
   * Results are sorted newest first by closed_at, and are paged. Call `AlignStore.getTeamAligns()` to load / load more
   * - Reference `AlignStore.isLoadingTeamAligns` for loading state
   */
  @computed
  get closedAligns(): ReadonlyModelList<AlignModel> {
    return this._aligns.get(ExerciseStatus.Closed);
  }

  /**
   * Replace `aligns` with a new list of aligns.
   */
  setAllAligns(aligns: AlignModel[], status: ExerciseStatus): void {
    this._aligns.get(status).setItems(aligns);
  }

  appendAligns(aligns: AlignModel[], status: ExerciseStatus): void {
    this._aligns.get(status).appendItems(aligns);
  }

  /**
   * Add an align, newly created in the FE, to the top of the FE's loaded aligns list.
   * This is a front-end "optimistic add", to match what the user will get if they refresh the page.
   */
  addNewlyCreatedAlign(align: AlignModel): void {
    this._aligns.get(ExerciseStatus.Open).prependItem(align);
  }

  get isLoadingAligns(): boolean {
    return this.openAligns.loading || this.closedAligns.loading;
  }

  /**
   * This property is dependent on you previously loading `aligns`.
   */
  @computed
  get hasAligns(): boolean {
    return this.hasOpenAligns || this.hasClosedAligns;
  }

  /**
   * This property is dependent on you previously loading `aligns`.
   */
  @computed
  get hasOpenAligns(): boolean {
    return !!this.openAligns.items?.length;
  }

  /**
   * This property is dependent on you previously loading `aligns`.
   */
  @computed
  get hasClosedAligns(): boolean {
    return !!this.closedAligns.items?.length;
  }

  /**
   * This property is dependent on you previously loading `aligns`.
   * @returns undefined if there is no open align
   */
  @computed
  public get latestOpenAlign(): AlignModel {
    // ExercisesController@index returns results sorted newest first
    return this.openAligns.items?.at(0);
  }

  /**
   * This property is dependent on you previously loading `aligns`.
   * * @returns undefined if there is no closed align
   */
  @computed
  public get latestClosedAlign(): AlignModel {
    // ExercisesController@index returns results sorted newest first
    return this.closedAligns.items?.at(0);
  }

  /**
   * Check if the team has "recent" open or closed align (where "recent" is defined by OLD_ALIGN_MONTHS_THRESHOLD)
   *
   * Full disclosure: This property exists for showing the `<InviteTeam>` card,
   * > "Your team hasn’t run an Align in over three months"
   *
   * This property is dependent on you previously loading `aligns`.
   */
  @computed
  get hasRecentAlign(): boolean {
    // open aligns are ordered by created_at, so we only need to check the top one
    const hasRecentOpen =
      this.latestOpenAlign &&
      moment().diff(moment(this.latestOpenAlign.created_at), 'months') <=
        OLD_ALIGN_MONTHS_THRESHOLD;

    // closed aligns are ordered by closed_at, so we only need to check the top one
    const hasRecentClosed =
      this.latestClosedAlign &&
      moment().diff(moment(this.latestClosedAlign.closed_at), 'months') <=
        OLD_ALIGN_MONTHS_THRESHOLD;

    return hasRecentOpen || hasRecentClosed;
  }

  @computed
  get membersById(): number[] {
    return this.members.items.reduce((acc, member) => {
      acc.push(member.id);
      return acc;
    }, []);
  }

  get activeOnboardingLinkCount(): number {
    return this.active_onboarding_link_count;
  }

  associateGroupMap() {
    this.group_ids = Object.values(this.group_map);
  }

  isManager = (memberId: number): boolean => {
    const managerId = this.manager_id ? this.manager_id : this.manager?.item?.id;
    return managerId === memberId || this.secondary_manager?.item?.id === memberId;
  };

  static fromJson(json: ModelJson) {
    return this._fromJson(json) as TeamModel;
  }

  static getOrNew(id) {
    return this._getOrNew(id) as TeamModel;
  }

  static get(id) {
    return this._get(id) as TeamModel;
  }
}

allModels.register({ TeamModel });

export default TeamModel;
