import { action, computed, observable } from 'mobx';

import { URLBuilder } from 'app/helpers/URLBuilder';
import { Model, ModelList, PagingInfo, PagingInfoModel, PagingMetaModel } from 'app/models';
import { CancelHandler, LOAD_METHOD } from 'app/models/ModelContainer';

/**
 * A ModelList that takes care of "paging" so you don't have to re-implment it.
 * - Every time you call `load()`, another page will be loaded (unless your previous load already reached the end)
 * - You can reset the paging back to the start when needed, by calling `resetExercisePagingInfo()`
 *
 * FAQ: What is the relation between this and `LegacyPaginatedModelList`?
 * A:
 * - This class is for use with AntD `<Pagination>` (which we do using `PagingMetaModel` and `PagingInfoModel`)
 * - `LegacyPaginatedModelList` is from 2019, and exclusively used with our `<LegacyPagination>` component
 */
export class PagedModelList<T extends Model, F = unknown> extends ModelList<T, F> {
  constructor(protected modelClass: typeof Model, pageSize: number) {
    super(modelClass);

    this.pageSize = pageSize;
    this.resetExercisePagingInfo();
  }

  private readonly pageSize: number;

  @observable private _meta = new PagingMetaModel();
  @computed get meta(): Readonly<PagingMetaModel> {
    return this._meta;
  }

  @action
  private setMeta(meta: PagingMetaModel): void {
    this._meta = meta;
  }

  @observable private _pagingInfo: PagingInfoModel;
  /**
   * Note that after the load call, the page already increments.
   * If you want to really know what page was returned by the response meta, use `latestPage`.
   */
  @computed get pagingInfo(): PagingInfo {
    return this._pagingInfo;
  }

  /**
   * The true page number returned by the last response meta (can't trust pagingInfo 100% because it gets incremented).
   */
  @computed get currentPage(): number {
    return this.meta.current_page;
  }

  @computed
  get shouldLoadMore(): boolean {
    return this._meta && this._meta?.current_page < this._meta?.last_page;
  }

  @action
  resetExercisePagingInfo = (): void => {
    this._pagingInfo = new PagingInfoModel({
      page: 1,
      page_size: this.pageSize,
    });
  };

  override async load(
    url: URLBuilder,
    params?: { [key: string]: any },
    config?: {
      dataKey?: string;
      forceRefresh?: boolean;
      throwError?: boolean;
      method?: LOAD_METHOD;
      redirectIfUnauthorized?: boolean;
      headers?: Record<string, any>;
      append?: boolean;
      skipCancel?: boolean;
      cancelHandler?: (handler: CancelHandler) => void;
      onResponse?: (response: any) => void;
    }
  ): Promise<any> {
    params = {
      ...params,
      page: this._pagingInfo.page,
      page_size: this._pagingInfo.page_size,
    };

    const response = await super.load(url, params, config);
    if (response?.meta) {
      this.setMeta(new PagingMetaModel(response.meta));
      this._pagingInfo.incrementPage();
    }

    return response;
  }
}
