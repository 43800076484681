import { observable } from 'mobx';

import Model, { ModelJson } from 'app/models/Model';
import OrganizationTeamListMetricsStore from 'app/stores/OrganizationTeamListMetricsStore';

export class OrganizationTeamListMetricsModel extends Model {
  public static readonly _store: OrganizationTeamListMetricsStore;

  @observable public id: string;
  @observable public name: string;
  @observable public manager_name: string;
  @observable public manager_email: string;
  @observable public number_of_members: number;
  @observable public members_with_perspective_count: number;

  @observable public perspectives_created?: number;
  @observable public has_perspective?: boolean;

  @observable public aligns_created?: number;
  @observable public aligns_closed?: number;
  @observable public has_align?: boolean;

  @observable public aligns_answered: number;

  @observable public team_habits_created?: number;
  @observable public has_team_pulse?: boolean;

  @observable public reflect_360s_created?: number;
  @observable public last_activity_at: string;
  @observable public created_at: string;

  // Perspective
  @observable public latest_perspective_activity_at?: string;
  @observable public latest_perspective_created_at?: string;
  @observable public latest_perspective_pair_report_viewed_at?: string;
  @observable public latest_perspective_team_report_viewed_at?: string;
  @observable public latest_manager_playbook_report_viewed_at?: string;

  // Align
  @observable public latest_align_activity_at?: string;
  @observable public latest_align_created_at?: string;
  @observable public latest_align_response_submitted_at?: string;
  @observable public latest_align_report_shared_at?: string;

  // Pulse
  @observable public latest_pulse_activity_at?: string;
  @observable public latest_member_pulse_created_at?: string;
  @observable public latest_team_pulse_created_at?: string;
  @observable public latest_team_pulse_response_submitted_at?: string;

  // Reflect360
  @observable public latest_reflect360_activity_at?: string;
  @observable public latest_reflect360_created_at?: string;
  @observable public latest_reflect360_response_submitted_at?: string;

  static fromJson(json: ModelJson): OrganizationTeamListMetricsModel {
    return this._fromJson(json) as OrganizationTeamListMetricsModel;
  }

  static getOrNew(id): OrganizationTeamListMetricsModel {
    return this._getOrNew(id) as OrganizationTeamListMetricsModel;
  }

  static get(id): OrganizationTeamListMetricsModel {
    return this._get(id) as OrganizationTeamListMetricsModel;
  }
}
